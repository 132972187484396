import * as React from 'react';
import { Container, Row, Col, Progress, Button } from 'reactstrap';
import { Layout } from '../Layout';
import { ComputerLiveAnnouncement } from './ComputerLiveAnnouncement';
import { ComputerLiveDisplay } from './ComputerLiveDisplay';
import socketIOClient from 'socket.io-client';
import { isMobileOnly } from 'react-device-detect';
import Cookies from 'universal-cookie';
import { formatScore } from '../util.js';
import TextareaAutosize from 'react-textarea-autosize';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../scss/Computer.scss';

import LogoutModal from './LogoutModal.js';
import DuplicateAuthModal from './DuplicateAuthModal.js';
import NotLoggedInModal from './NotLoggedInModal.js';

const ReactMarkdown = require('react-markdown');
const cookies = new Cookies();
const config = require('../config');

//established here for re-use throughout component
let endpoint,
	socket,
	answerField,
	submitButton,
	rootEl;

export class Computer extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			question: false,
			progress: 0,
			endpoint: config.endpoint + '/users', //last part is socket.io namespace
			googleAuthUrl: false,
			authedUser: false,
			isAdmin: false,
			nickname: false,
			nicknameInput: '',
			isLogoutModalOpen: false,
			isDuplicateAuthModalOpen: false,
			duplicateAuthEmail: '',
			isNotLoggedInModalOpen: false,

			users: 0,
			guess: '',
			guessMessage: '',
			previousGuess: '',
			score: 0,

			//live trivia stuff
			isLive: false,
			announcement: false,
			showScores: false,
			currentScores: [],
			revealedAnswer: false,
			answerStatus: false,
			hasGuessed: false,
			activeQuestion: false,
			currentRound: false,
			questionNumber: false,

			//RTI additions
			rtiEmail: '',
			submittingEmail: false
		}

		this.formRef = React.createRef();
		this.pressReturn = this.pressReturn.bind(this);

		this.handleGuessChange = this.handleGuessChange.bind(this);
		this.handleGuessSubmit = this.handleGuessSubmit.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.toggleLogoutModal = this.toggleLogoutModal.bind(this);
		this.toggleNotLoggedInModal = this.toggleNotLoggedInModal.bind(this);
		this.confirmDuplicateAuth = this.confirmDuplicateAuth.bind(this);
		this.cancelDuplicateAuth = this.cancelDuplicateAuth.bind(this);
		this.saveNickname = this.saveNickname.bind(this);
		this.clearNickname = this.clearNickname.bind(this);
		this.handleNicknameChange = this.handleNicknameChange.bind(this);

		//RTI handler binds
		this.handleEmailSubmit = this.handleEmailSubmit.bind(this);
		this.handleEmailChange = this.handleEmailChange.bind(this);
	}

	componentDidMount() {
		if (config.enableTestUsers && config.testJwtUser) {
			cookies.set('triviaComputerJWT', config.testJwtUser, { sameSite: 'lax' });
		}

		let socketOptions = {},
			token = '';

		//localStorage method
		if (window.localStorage.getItem('triviaComputerJWT') !== null) {
			token = window.localStorage.getItem('triviaComputerJWT');

		//cookie method (to be deprecated)
		} else if (cookies.get('triviaComputerJWT') !== undefined) {
			token = cookies.get('triviaComputerJWT');
		}

		if (token !== '') {
			socketOptions.auth = {
				token: token
			}			
		}

		endpoint = this.state.endpoint;
		socket = socketIOClient(endpoint, socketOptions);

		answerField = document.querySelector('#answer');
		submitButton = document.querySelector('#submit');
		rootEl = document.querySelector('#root');

		//locking scroll when input focused for mobile
		if (isMobileOnly) {
			//disabled for now, need to rethink
			/*var scrollLock = false,
				scrollLimit = 114;

			answerField.addEventListener('focus', (e) => {
				scrollLock = true;

				setTimeout(() => {
					scrollLock = false;
				}, 500);
			});

			answerField.addEventListener('blur', (e) => {
				scrollLock = false;
			});

			document.addEventListener('scroll', (e) => {
				if (scrollLock && document.documentElement.scrollTop > scrollLimit) {
					document.documentElement.scrollTop = scrollLimit;
				}
			});*/
		} else {
			answerField.focus();
		}

		socket.on('authedUser', data => this.handleAuthedUser(data));
		socket.on('duplicateAuth', data => this.handleDuplicateAuth(data));
		socket.on('handleRemoteLogout', () => this.cancelDuplicateAuth());
		socket.on('notLoggedIn', () => this.handleNotLoggedIn());
		socket.on('nicknameUpdated', data => this.setState({ nickname: data.nickname }));
		socket.on('googleAuthUrl', data => this.setState({ googleAuthUrl: data.url }));
		socket.on('error', err => this.handleLogout()); //deletes cookie & logs out

		socket.on('changeQuestion', data => this.handleChangeQuestion(data));
		socket.on('updateTimer', data => this.setState({ progress: data }));
		socket.on('updateUsers', data => this.setState({ users: data.users }))
		socket.on('correctAnswer', data => this.handleGuessResponse(true, data.original));
		socket.on('updateScore', data => this.setState({ score: data.score }));
		socket.on('incorrectAnswer', () => this.handleGuessResponse(false));

		socket.on('newAnnouncement', data => this.setState({ announcement: data.announcement }));
		socket.on('isLive', data => this.beginLiveTrivia(data));
		socket.on('endLive', () => this.endLiveTrivia());
		socket.on('clientShowScores', data => this.showScores(data.scores));
		socket.on('clientHideScores', () => this.hideScores());
		socket.on('nextQuestion', data => this.handleChangeQuestion(data));
		socket.on('questionOver', () => this.handleQuestionOver());
		socket.on('clientRevealAnswer', data => this.handleRevealAnswer(data));
		socket.on('answerStatus', data => this.handleAnswerStatus(data));
		socket.on('updateRoundInfo', data => this.handleRoundInfo(data));

		//rti stuff
		socket.on('authSuccess', data => {
			if (data.jwt) {
				cookies.set('triviaComputerJWT', data.jwt, {
					path: '/',
					sameSite: 'lax',
					expires: new Date(Date.now() + 315360000000)
				});

				//including localStorage option, will eventually replace cookie entirely
				window.localStorage.setItem('triviaComputerJWT', data.jwt);
			}

			window.location.reload(); //easiest way to use new login JWT
		});
	}

	handleAuthedUser(data) {
		if (data.email) {
			this.setState({ authedUser: data.email });
		}

		if (data.score) {
			this.setState({ score: data.score });
		}

		if (data.nickname) {
			this.setState({ nickname: data.nickname });
		}

		if (data.admin) {
			this.setState({ isAdmin: true });
		}
	}

	handleDuplicateAuth(data) {
		this.setState({
			duplicateAuthEmail: data.email, 
			isDuplicateAuthModalOpen: true
		});
	}

	confirmDuplicateAuth() {
		this.setState({ 'isDuplicateAuthModalOpen': false });
		socket.emit('confirmDuplicateAuth');
	}

	cancelDuplicateAuth() {
		this.handleLogout();
	}

	handleNotLoggedIn() {
		this.setState({ 'isNotLoggedInModalOpen': true });
	}

	saveNickname() {
		if (this.state.nicknameInput.trim() !== '') {
			socket.emit('updateNickname', { nickname: this.state.nicknameInput });
		}
	}

	handleNicknameChange(event) {
		this.setState({ nicknameInput: event.target.value });
	}

	clearNickname() {
		let oldNickname = this.state.nickname;
		this.setState({ nickname: false, nicknameInput: oldNickname });
		socket.emit('clearNickname');
	}
	
	handleLogout() {
		if (cookies.get('triviaComputerJWT') !== undefined) {
			cookies.remove('triviaComputerJWT');	
		}

		if (window.localStorage.getItem('triviaComputerJWT') !== null) {
			window.localStorage.removeItem('triviaComputerJWT');
		}
		
		window.location.reload(); //easiest way to clear settings
	}
	
	toggleLogoutModal() {
		this.setState({ isLogoutModalOpen: !this.state.isLogoutModalOpen });
	}

	toggleNotLoggedInModal() {
		this.setState({ isNotLoggedInModalOpen: !this.state.isNotLoggedInModalOpen });
	}

	handleChangeQuestion(question) {
		answerField.classList.remove('incorrect', 'correct', 'shake');
		submitButton.classList.remove('correct', 'incorrect', 'shake');
		submitButton.disabled = false;
		answerField.disabled = false;

		if (!isMobileOnly) {
			answerField.focus();
		}

		if (this.state.isLive) {
			this.setState({
				guessMessage: 'Submit a response before the timer runs out.  \nMake sure to press Enter or click the Submit button!',
				previousGuess: '',
				activeQuestion: true
			});

			rootEl.classList.add('bg-flash');
			setTimeout(() => {
				rootEl.classList.remove('bg-flash');
			}, 1000);
		} else {
			this.setState({ guessMessage: '' });
		}

		this.setState({
			hasGuessed: false, 
			question: question,
			guess: ''
		});
	}

	handleGuessResponse(correct, answer = null) {
		if (correct) {
			this.setState({ guess: answer });
			answerField.classList.remove('incorrect');
			answerField.classList.add('correct');
			answerField.disabled = true;

			submitButton.classList.remove('incorrect');
			submitButton.classList.add('correct');
			submitButton.disabled = true;

			this.setState({ guessMessage: 'Correct!' });
			document.querySelector('#score').classList.add('active');
			
		} else {
			answerField.classList.add('incorrect', 'shake');
			submitButton.classList.add('incorrect', 'shake');
			this.setState({ guessMessage: 'Incorrect! Try again.' });

			setTimeout(() => {
				answerField.classList.remove('shake');
				submitButton.classList.remove('shake');
			}, 400);
		}
	}
	
	handleGuessChange(event) {
		this.setState({ guess: event.target.value });
	}

	handleGuessSubmit(event) {
		event.preventDefault();

		//prevent non-active guesses when live
		if (this.state.isLive && !this.state.activeQuestion) {
			return false;
		}

		if (this.state.guess.trim() !== '') {
			if (!this.state.hasGuessed) {
				this.setState({ hasGuessed: true });

			} else if (this.state.isLive) {
				answerField.classList.add('pulse');
				setTimeout(() => {
					answerField.classList.remove('pulse');
				}, 450);			
			}

			//prevent multiple submissions of same guess
			if (this.state.guess.length > 255) {
				return false;
			}

			if (this.state.guess !== this.state.previousGuess) {
				this.setState({ previousGuess: this.state.guess });
				socket.emit('updateGuess', { guess: this.state.guess });

				if (this.state.isLive) {
					this.setState({ guessMessage: 'You can revise your answer while there is time remaining  \nMake sure to press Enter or click the Submit button if you do!' });
				}
			}
		}
	}

	pressReturn(e) {
		if (e.key === 'Enter') {
			e.preventDefault();
			this.formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
		}
	}

	//live trivia
	beginLiveTrivia(data) {
		this.setState({
			isLive: true,
			progress: 0,
			guess: '',
			previousGuess: ''
		});

		if (data.announcement) {
			this.setState({ announcement: data.announcement });
		}

		if (data.questionSetName && data.activeQuestion) {
			this.setState({ currentRound: data.questionSetName, questionNumber: data.question.order });
		}

		answerField.classList.remove('incorrect', 'correct', 'shake');
		submitButton.classList.remove('correct', 'incorrect', 'shake');
		submitButton.disabled = false;
		answerField.disabled = false;
		this.setState({ guessMessage: '' });

		if (data.activeQuestion) {
			this.setState({
				question: data.question,
				activeQuestion: true
			});

		} else if (data.revealAnswer) {
			this.setState({
				question: data.question,
				currentRound: data.questionSetName,
				questionNumber: data.question.order
			});			

		} else {
			this.setState({ question: false })
		}
	}

	endLiveTrivia() {
		this.setState({ isLive: false, currentRound: false });
	}

	showScores(scores) {
		this.setState({
			currentScores: scores,
			showScores: true
		});
	}

	hideScores() {
		this.setState({ showScores: false });
	}

	handleRevealAnswer(data) {
		if (data.reveal) {
			this.setState({ revealedAnswer: data.answer });
		} else {
			this.setState({ revealedAnswer: false, answerStatus: false });
		}
	}

	handleAnswerStatus(data) {
		let answerText = 'incorrect.';

		if (data.answerStatus === 'correct') {
			answerText = 'correct.';
		} else if (data.answerStatus === 'half') {
			answerText = 'half correct.';
		}

		this.setState({
			answerStatus: data.answerStatus,
			guessMessage: 'Your answer was marked ' + answerText
		});
	}

	handleQuestionOver() {
		answerField.disabled = true;
		submitButton.disabled = true;
		this.setState({ guessMessage: 'Time\'s up!' });

		if (!this.state.hasGuessed && this.state.guess.trim() !== '') {
			socket.emit('updateGuess', { guess: this.state.guess });
			this.setState({ previousGuess: this.state.guess, hasGuessed: true });
		}

		this.setState({
			activeQuestion: false,
			progress: { countdown: 100, remaining: false }
		});
	}

	handleRoundInfo(data) {
		this.setState({
			currentRound: data.theme,
			questionNumber: data.questionNumber
		});
	}

	//RTI handlers
	handleEmailSubmit() {
		if (this.state.rtiEmail.trim() !== '') {
			this.setState({ submittingEmail: true });
			socket.emit('rtiLogin', { email: this.state.rtiEmail });
		}
	}

	handleEmailChange(event) {
		this.setState({ rtiEmail: event.target.value });
	}


	render() {
		const {
			question,
			progress,
			users,
			googleAuthUrl,
			authedUser,
			isAdmin,
			score,
			isLive,
			announcement,
			showScores,
			nickname,
			nicknameInput
		} = this.state;

		const loadingMessage = (isLive) ? '__Welcome to Trivia with CETA!__  \nQuestions will appear here, and you will have __30 seconds__ to answer each one. Josh will read the question once before the text appears and the timer starts. Enter your answers in the text field below, and make sure to submit them by pressing return or clicking the submit arrow.' : 'Loading...';
		const calculatedScore = (isLive) ? formatScore(score) : (score * 100);
		const revealedAnswer = (this.state.revealedAnswer) ? <div id="revealAnswer">Answer: <span><ReactMarkdown source={ this.state.revealedAnswer } /></span></div> : '';

		//nickname input or display
		let nicknameDisplay;
		if (nickname) {
			let editNick = (!isLive) ? <div title="Change your nickname. Only editable before live trivia begins." onClick={this.clearNickname} className="edit"><FontAwesomeIcon icon="edit" /></div> : '';
			nicknameDisplay = <Col xs="12" className="nickDisplay"><div>{editNick}<span>{nickname}</span></div></Col>;

		} else {
			nicknameDisplay = <Col className="setNick"><div>Enter Your Name<span>Other players will see this next to your score.</span></div><input type="text" id="setNick" maxLength="50" value={nicknameInput} onChange={this.handleNicknameChange} /><br /><Button color="warning" onClick={this.saveNickname}>Save Name</Button></Col>;
		}
		
		nicknameDisplay = (authedUser) ? nicknameDisplay : ''; //null if not authed

		return (
			<Layout isLive={isLive} users={ users } googleAuthUrl={googleAuthUrl} authedUser={authedUser} handleLogout={this.toggleLogoutModal} isAdmin={isAdmin} handleEmailSubmit={this.handleEmailSubmit} handleEmailChange={this.handleEmailChange} rtiEmail={this.state.rtiEmail} submittingEmail={this.state.submittingEmail}>
				<LogoutModal isOpen={this.state.isLogoutModalOpen} toggle={this.toggleLogoutModal} doLogout={this.handleLogout} />
				<DuplicateAuthModal isOpen={this.state.isDuplicateAuthModalOpen} cancelAuth={this.cancelDuplicateAuth} doAuth={this.confirmDuplicateAuth} userEmail={this.state.duplicateAuthEmail} />
				<NotLoggedInModal isOpen={this.state.isNotLoggedInModalOpen} toggle={this.toggleNotLoggedInModal} googleAuthUrl={googleAuthUrl} />

				<Container id="Announce">
					<Row>
						{nicknameDisplay}

						<ComputerLiveAnnouncement isLive={isLive} announcement={announcement} />
						<ComputerLiveDisplay isLive={isLive} announcement={announcement} showScores={showScores} currentScores={this.state.currentScores} />
					</Row>
				</Container>

				<Container id="Computer" className={ isLive ? 'isLive' : '' }>
					<Row className="align-items-center noMargin">
						<Col xs="12" className="computerCol text-center">
							<div id="score" className={ "" + (score > 0 ? 'active ' : '') + (isLive ? 'isLive' : '') }>
								Score: {calculatedScore}
							</div>
							<div id="computer" className="computer">
								<div className="screen monitor">
									<div className="content">
										<ReactMarkdown source={ question.question ? question.question : loadingMessage } />

										{revealedAnswer}

										<div className="progressContainer">
											<Progress color="info" value={ progress.countdown ? progress.countdown : 0 } />
										</div>
									</div>

									<div className="timeRemaining">{progress.remaining ? progress.remaining : ''}</div>

									<div className="base">
										<div className="grey-shadow"></div>
										<div className="foot top"></div>
										<div className="foot bottom"></div>
									</div>
								</div>

								<div className={ "roundInfo" + (isLive && this.state.currentRound ? ' active' : '') }>
									<span className="number">Q{this.state.questionNumber}</span><span className="round">{this.state.currentRound}</span>
								</div>
							</div>
						</Col>
						<Col xs="12" className="answerCol">
							<form onSubmit={this.handleGuessSubmit} ref={this.formRef}>
								<TextareaAutosize id="answer" maxLength={255} autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" value={this.state.guess} onKeyDown={this.pressReturn} onChange={this.handleGuessChange} className={(isLive && this.state.hasGuessed) ? 'hasGuessed' : '' } />
								<button id="submit" name="btnSubmit" onClick={this.handleGuessSubmit} className={(isLive && this.state.hasGuessed) ? 'hasGuessed' : '' }>
									<FontAwesomeIcon icon="share" />
								</button>
								<div id="answerStatus" className={ this.state.answerStatus ? this.state.answerStatus : 'disabled' }>
									{ this.state.answerStatus === 'correct' ? <FontAwesomeIcon icon="check" /> : '' }
									{ this.state.answerStatus === 'incorrect' ? <FontAwesomeIcon icon="times" /> : '' }
									{ this.state.answerStatus === 'half' ? <span>&frac12;</span> : '' }
								</div>
							</form>

							<div id="guessStatus">
								{(isLive && this.state.previousGuess !== '') ? <div className="yourAnswer">Your answer: <b>{this.state.previousGuess}</b></div> : '' }
								<ReactMarkdown className="message" source={this.state.guessMessage} />
							</div>
						</Col>
					</Row>
				</Container>
			</Layout>
		);
	}
}
